<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div
        v-else-if="rows.length !== 0"
        class="pixels-table"
      >
        <div class="head-card">
          <div class="head-card-top">
            <h4>{{ $t('Pixels') }}</h4>
            <feather-icon
              v-b-tooltip.hover.top="$t('Close')"
              icon="XIcon"
              size="18"
              class="toggle-close cursor-pointer"
              @click="$router.go(-1)"
            />
          </div>
          <hr>
        </div>
        <!-- search input -->
        <div class="custom-search flex-wrap">
          <div class="total">
            <strong>{{ $t('pixels.nopixels') }}: {{ rows.length }}</strong>
          </div>
          <div class="table-filters">
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('form.search') }}</label>
              <b-input-group>
                <b-form-input
                  ref="searchInput"
                  :value="searchTerm"
                  :placeholder=" $t('form.search')"
                  type="text"
                  class="d-inline-block"
                  @keyup.enter="searchTerm = $event.target.value"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="searchTerm = $refs.searchInput.vModelValue"
                  >
                    <feather-icon
                      icon="SearchIcon"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div
              v-if="$route.params.userId"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="btn-icon"
                :to="{ path: `/users/${$route.params.userId}/pixels/create`}"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                <span>{{ $t('pixels.pixeladd') }}</span>
              </b-button>
            </div>
          </div>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'status', type: 'asc' },
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <!-- Slot: Table Column -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span
              v-if="props.column.label ==='Status'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.status') }}
            </span>
            <span
              v-else-if="props.column.label ==='Name'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.name') }}
            </span>
            <span
              v-else-if="props.column.label ==='Action'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.action') }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Status -->
            <span
              v-if="props.column.field === 'status'"
              class="text-nowrap"
            >
              <b-form-checkbox
                :checked="props.row.status === 'active' ? true : false"
                name="check-button"
                switch
                @change="changeStatus(props.row._id)"
              />
            </span>

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'name'"
              class="text-nowrap"
            >
              <span class="d-flex justify-content-between">
                <router-link :to="'/users/'+ props.row.user_id + '/pixels/'+ props.row._id">
                  {{ props.row.name }}
                </router-link>

                <b-button
                  v-b-tooltip.hover.top="$t('widgets.copybutton')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  class="btn-sm btn-icon"
                  @click="doCopy(props.row._id)"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
              </span>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
            >
              <span>
                <b-dropdown
                  variant="link"
                  right
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :to="'/users/'+ props.row.user_id + '/pixels/'+ props.row._id + '/edit/'"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.edit') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="danger"
                    @click="pixelDelete(props.row._id, props.row.name)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','20','40']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
      <div v-else>
        <div class="head-card">
          <div class="head-card-top">
            <h4>{{ $t('Pixels') }}</h4>
            <feather-icon
              v-b-tooltip.hover.top="$t('Close')"
              icon="XIcon"
              size="18"
              class="toggle-close cursor-pointer"
              @click="$router.go(-1)"
            />
          </div>
          <hr>
        </div>
        <div
          class="text-center"
        >
          <h5>{{ $t('pixels.nopixels') }}</h5>
          <p>{{ $t('pixels.add') }}:</p>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            :to="{ path: `/users/${$route.params.userId}/pixels/create`}"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            {{ $t('pixels.pixeladd') }}
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import { getUserData } from '@/auth/utils'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BSpinner, BPagination, BFormInput, BInputGroup, BInputGroupAppend, BFormSelect, BFormCheckbox, BDropdown, BDropdownItem, VBTooltip, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    Breadcrumbs,
    UserToolbar,
    BSpinner,
    BCard,
    VueGoodTable,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      pageTitle: '',
      authUserData: getUserData(),
      loading: true,
      pageSubtitle: '',
      account: {},
      breadcrumbItems: [],
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'Status',
          field: 'status',
          width: '3%',
          type: 'boolen',
        },
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Action',
          width: '5%',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        active       : 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    if (this.$route.params.userId !== undefined) {
      this.getUserData()
    } else {
      this.fethPixels()
    }
  },
  methods: {
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.fethPixels()
    },
    setBreabcrumbs() {
      if (this.$route.params.userId !== undefined) {
        this.breadcrumbItems = [
          {
            text: this.$t('page.menu.users'),
            to: '/users',
          },
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: 'Pixels',
            active: true,
          },
        ]
      } else {
        this.breadcrumbItems = [
          {
            text: 'Pixels',
            active: true,
          },
        ]
      }
    },
    async fethPixels() {
      const responseData = this.$route.params.userId !== undefined ? await useJwt.accountPixels(this.$route.params.userId) : await useJwt.pixels()
      this.rows = responseData.data.pixels || []
      this.loading = false
      this.setBreabcrumbs()
    },
    pixelDelete(id, name) {
      this.$swal({
        title: `Delete ${name}?`,
        text: 'Do you really want to delete this?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Delete',
            text: 'Successfully deleted',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          useJwt.deletePixel(id)
          this.row = []
          this.fethPixels()
        }
      })
    },
    doCopy(id) {
      const copyTag = `<img src="//track.phoenix-widget.com/target.gif?id=${id}" />`
      this.$copyText(copyTag).then(() => {
        this.showToast('Text copied', '', 'primary')
      }, e => {
        this.showToast('Can not copy!', e, 'danger')
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    async changeStatus(id) {
      const responseData = await useJwt.getPixel(id)
      const pixel = responseData.data.pixel || []
      pixel.status = pixel.status === 'inactive' ? 'active' : 'inactive'
      if (pixel.status === 'active') {
        this.$swal({
          icon: 'success',
          title: 'Activation!',
          text: 'Successfully activated.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        useJwt.setPixel(id, { pixel })
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            pixel.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setPixel(id, { pixel })
        })
      }
    },
  },
}
</script>
<style>
.pixels-table td > .btn {
  padding: inherit;
}

.pixels-table td + td {
  text-align: center!important;
}
</style>
